.holder {
    display: flex;
    justify-content: center;
}

.holder > * {
    padding: 10px;
    margin: 10px;
}

.stationButtons {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
}

.stationButtons > * {
    margin: 10px;
}